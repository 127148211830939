export default {

  name: 'PriceList',

  priceList: {
    assignedDays: 0,
    factorPerDay: 0,
    factorPerHour: 0,
    hourIntervalPricings: [
      {
        from: 0,
        until: 0,
        price: 0
      },
    ],
    hoursPerWeek: 0,
    name: '',
    price: 0,
    refNr:'',
    type: '',
  }
}