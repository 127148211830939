<template>
  <div v-if="priceList" class="grid-x grid-padding-x">
    <div class="cell large-4 medium-12 small-12">
      <md-field :class="getValidationClass('name')">
        <label>Name</label>
        <md-input name="priceList-name" id="priceList-name" autocomplete="off"
                  v-model="priceList.name" :disabled="sending"/>
        <span class="md-error" v-if="!$v.priceList.name.required">Name ist notwendig</span>
        <span class="md-error" v-if="!$v.priceList.name.valid">Unzulässige Zeichen enthalten</span>
      </md-field>
    </div>

    <div class="cell large-4 medium-6 small-6">
      <md-field :class="getValidationClass('type')">
        <label>Typ</label>
        <md-select v-model="priceList.type" autocomplete="off" @md-selected="resizeDialogElements" :disabled="sending">
          <md-option v-for="listType of labels.priceListTypes" v-bind:value="listType" :key="listType">
            {{labels.priceListType[listType]}}
          </md-option>
        </md-select>
        <span class="md-error" v-if="!$v.priceList.type.required">Typ ist notwendig</span>
      </md-field>
    </div>

    <div v-if="priceList.type === 'FACTOR_DAYS'" class="cell large-4 medium-6 small-6">
      <md-field :class="getValidationClass('factorPerDay')">
        <label>€ pro Tag</label>
        <md-input type="number" name="factorPerDay" id="factorPerDay" autocomplete="off"
                  v-model="priceList.factorPerDay" :disabled="sending"/>
      </md-field>
    </div>

    <div v-if="priceList.type === 'FACTOR_HOURS'" class="cell large-4 medium-6 small-6">
      <md-field :class="getValidationClass('factorPerHour')">
        <label>€ pro Wochenstunde</label>
        <md-input type="number" name="factorPerHour" id="factorPerHour" autocomplete="off"
                  v-model="priceList.factorPerHour" :disabled="sending"/>
      </md-field>
    </div>

    <div v-if="priceList.type === 'INTERVAL_HOURS'" class="cell large-12 medium-12 small-12">
      <br>
      <md-table :id="getContainerId()" v-model="priceList.hourIntervalPricings"  @md-selected="onSelectInterval"
                md-sort="from" md-sort-order="asc" md-card
                style="height: 495px;">
        <md-table-toolbar>
          <p class="md-title ai-number">Preise für <span style="color: cornflowerblue; font-size: larger">
            {{priceList.hourIntervalPricings.length}}</span> Stundeninterval<span v-if="priceList.hourIntervalPricings.length !== 1">le</span>
            &nbsp;<span style="font-size: small">(bezogen auf Wochenstunden)</span>
            <md-tooltip>Bezogen auf Wochenstunden</md-tooltip>
          </p>
          <md-button class="md-fab md-mini md-primary" @click="onAddInterval">
            <md-icon>add</md-icon>
            <md-tooltip md-direction="bottom">
              Neues Intervall anlegen<br>
            </md-tooltip>
          </md-button>
          <md-button class="md-fab md-mini md-plain" @click="onDeleteSelectedInterval" :disabled="selectedPriceListInterval === null">
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="bottom">
              Intervall löschen<br>
            </md-tooltip>
          </md-button>
        </md-table-toolbar>
        <md-table-row :id="getId(item.id)" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)"
                      md-selectable="single" md-auto-select>
          <md-table-cell md-label="von" md-sort-by="from">
            <md-field>
              <label>von</label>
              <md-input type="number" v-model="item.from" :disabled="sending"/>
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="bis" md-sort-by="until">
            <md-field>
              <label>bis</label>
              <md-input type="number" v-model="item.until" :disabled="sending"/>
            </md-field>
          </md-table-cell>
          <md-table-cell md-label="Preis" md-sort-by="price">
            <md-field>
              <label>Preis</label>
              <md-input type="number" v-model="item.price" :disabled="sending"/>
            </md-field>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>

  </div>
</template>

<script>
  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate';
  import {required, helpers, not} from 'vuelidate/lib/validators';

  const invalidCharacters = helpers.regex('invalidCharacters', /[^a-zA-Z äöüßÄÖÜ\-éèáàô0-9.,;()/_]+/);

  export default {
    name: 'PriceListForm',
    mixins: [validationMixin],

    props: {
      priceList: {
        required: true
      },
      mode: {
        required: true
      },
      sending: {
        required: true
      },
    },

    mounted() {
      window.addEventListener('resize', (event) => {
        this.resizeDialogElements(event);
      }, { passive: true });
    },

    updated() {
      if (this.mode !== 'add') {
        this.validatePriceList();
        this.resizeDialogElements();
      }
    },

    data: () => ({
      invalid: true,
      selectedPriceListInterval: null,
    }),

    validations: {
      priceList: {
        name: {
          required,
          valid: not(invalidCharacters),
        },
        type: {
          required,
        },
      },
    },

    methods: {
      getId(id) {
        return 'hour-interval-id-' + id;
      },

      getContainerId() {
        return 'interval-container-' + this.mode;
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      resizeDialogElements() {
        setTimeout(() => jQuery('.md-table-head-label').attr('style', 'padding: 0px -5px 0px 24px;'), 10);
      },

      getValidationClass(fieldName) {
        let field = this.$v.priceList[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validatePriceList() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },

      onSelectInterval(item) {
        if (item) {
          this.selectedPriceListInterval = JSON.parse(JSON.stringify(item));
        } else {
          this.selectedPriceListInterval = null;
        }
      },

      onDeleteSelectedInterval() {
        if (this.selectedPriceListInterval) {
          let newHourIntervalPricings = [];
          for (let interval of this.priceList.hourIntervalPricings) {
            if (interval.id !== this.selectedPriceListInterval.id) {
              newHourIntervalPricings.push(interval);
            }
          }
          this.priceList.hourIntervalPricings = JSON.parse(JSON.stringify(newHourIntervalPricings));

          this.updateIds();
        }
      },

      onAddInterval() {
        this.priceList.hourIntervalPricings.push({
          from: 0,
          until: 0,
          price: 0
        });

        this.updateIds();
        setTimeout(() => {
          jQuery('#' + this.getId(this.priceList.hourIntervalPricings.length - 1)).trigger('click');
          let list = jQuery('#' + this.getContainerId()).children().first().next();
          list.animate({scrollTop: list.children('table').height()}, 'slow');
        }, 150);
      },

      updateIds() {
        let i = 0;
        for (let interval of this.priceList.hourIntervalPricings) {
          interval.id = i++;
        }
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style lang="scss" scoped>
  h5 {
    font-weight: 300;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }

  [type=button] {
    border-radius: 50%;
  }

  .md-table-cell {
    padding: 0;
  }

  .md-table-cell-container {
    padding-top: 0;
    padding-bottom: 0;
  }
</style>

