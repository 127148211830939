<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.financeModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success"><h3><i class="fi-pricetag-multiple" style="color: black"></i>&nbsp;&nbsp;&nbsp;Preislisten
        </h3></div>
      </div>
      <div v-if="organization && organization.facilities && organization.facilities.length <= 0"
           class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Preislisten"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell large-6">
          <div>
            <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                      @md-selected="onSelect" style="height: 850px">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                  <div class="cell large-3 hide-for-medium-only hide-for-small-only">
                    <p class="md-title ai-number">{{searched.length}} Preisliste<span v-if="searched.length !== 1">n</span></p>
                  </div>
                  <div class="cell large-6 medium-7 small-12">
                    <md-field v-if="availableFacilities && availableFacilities.length > 0">
                      <label>Einrichtung</label>
                      <md-select v-model="selectedFacilityReferenceNumber"
                                 :disabled="sending || availableFacilities.length <= 1"
                                 @md-selected="onSelectFacility">
                        <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                   :key="facility.referenceNumber">
                          {{ facility.longName }}
                        </md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="cell large-3 medium-5 small-12">
                    <md-field md-clearable class="md-toolbar-section-end">
                      <md-input placeholder="Suche Preisliste" v-model="search"
                                @input="searchOnTable"/>
                    </md-field>
                  </div>
                  <div class="cell" style="padding-bottom: 15px;">
                    <div class="grid-x grid-padding-x">
                      <div class="cell large-3 medium-7 hide-for-small-only">
                        <div v-if="sending">
                          <vue-simple-spinner></vue-simple-spinner>
                        </div>
                      </div>
                      <div class="cell large-6 hide-for-medium-only hide-for-small-only">
                      </div>
                      <div class="cell large-3 medium-5">
                        <md-button @click="onAddPriceList"
                                   :disabled="sending || !(isTreasurer || isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                                   class="md-icon-button md-raised md-primary">
                          <md-icon>add</md-icon>
                          <md-tooltip>Neue Preisliste hinzufügen</md-tooltip>
                        </md-button>
                        <md-button @click="onDeletePriceList"
                                   :disabled="!selected || sending || !(isTreasurer || isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                                   class="md-icon-button md-raised md-accent">
                          <md-icon>delete</md-icon>
                          <md-tooltip>Preisliste löschen</md-tooltip>
                        </md-button>
                      </div>
                    </div>
                  </div>

                </div>
              </md-table-toolbar>

              <md-table-empty-state v-if="search"
                                    md-label="Keine Preisliste gefunden"
                                    :md-description="`Keine Preisliste mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
              </md-table-empty-state>

              <md-table-row :id="item.refNr" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
                <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}
                </md-table-cell>
                <md-table-cell md-label="Typ" md-sort-by="type">{{ labels.priceListType[item.type] }}
                </md-table-cell>
                <md-table-cell md-label="Details">
                  <span v-if="item.type === 'INTERVAL_HOURS'">
                    {{ item.hourIntervalPricings.length }} Stundeninterval<span v-if="item.hourIntervalPricings.length !== 1">le</span>
                  </span>
                  <span v-else-if="item.type === 'FACTOR_HOURS'">
                    {{ item.factorPerHour | currency}}
                  </span>
                  <span v-else-if="item.type === 'FACTOR_DAYS'">
                    {{ item.factorPerDay | currency}}
                  </span>
                </md-table-cell>
              </md-table-row>

            </md-table>
          </div>
        </div>
        <div class="cell large-6">

          <form novalidate @submit.prevent="validatePriceList">
            <md-card style="min-height: 850px;">

              <md-card-header>
                <h4 style="font-size: 1.6rem; font-weight: 300">{{selectedFacilityName}}</h4>
                <br>
                <p v-if="selectedPriceList" class="md-title">
                  <span class="hide-for-medium-only hide-for-small-only">Preisliste: </span>
                  <span class="title-thin">{{selectedPriceList.name}}</span>
                </p>
                <h5 v-else><i><p>Bitte wählen Sie eine Preisliste aus</p></i></h5>
              </md-card-header>

              <md-card-content>
                <PriceListForm v-if="selectedPriceList" ref="priceListForm" :priceList="selectedPriceList" :sending="sending" mode="update">
                </PriceListForm>
                <div v-if="selectedPriceList" class="cell text-left">
                  <br><br>
                  <button class="button success" style="margin-bottom: 0" :disabled="sending || pristine()" type="submit">
                    <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
                  </button>
                </div>
              </md-card-content>

            </md-card>
          </form>

        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmSaveChangedDataDialog">
        <div v-if="lastSelectedPriceList" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><i class="fi-price-tag"></i>&nbsp;&nbsp;&nbsp;Änderungen an Preisliste bestätigen
          </md-dialog-title>
          <div class="cell">
            <md-content>Es wurden Änderungen an der Preisliste&nbsp;<span class="title">{{lastSelectedPriceList.name}}</span>
              durchgeführt. Sollen diese gespeichert werden?
            </md-content>
            <br>
            <br>
            <div class="text-center">
              <md-checkbox class="md-primary" v-model="updateAssignedFees">Gebühren mit diesen Änderungen neu berechnen?
                <md-tooltip class="md-tooltip-block"><b>Achtung!</b><br>Alle Gebühren, die diese Preisliste verwenden werden mit diesen Änderungen neu berechnet.</md-tooltip>
              </md-checkbox>
            </div>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="updatePriceList" class="button success" :disabled="sending"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, speichern</button>
            <button class="button alert" style="margin-left: 1rem;"
                    @click="closeSavePriceListDialog"
                    type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="closeSavePriceListDialog">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeletePriceListDialog">
        <div v-if="selectedPriceList" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <i class="fi-price-tag"></i>&nbsp;&nbsp;&nbsp;Preisliste der Einrichtung <span
              class="title">{{selectedFacilityName}}</span> löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Preisliste <span class="title"><b>{{selectedPriceList.name}}</b></span> der Einrichtung <span
                class="title">{{selectedFacilityName}}</span> wirklich gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button @click="deletePriceList" class="button alert" :disabled="sending"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
              Preisliste jetzt löschen
            </button>
            <button class="button success" style="margin-left: 1rem;" @click="showDeletePriceListDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeletePriceListDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <EditPriceListDialog :priceList="newPriceList"
                           :facilityReferenceNumber="selectedFacilityReferenceNumber" mode="add"
                           :showDialog="showEditPriceListDialog"
                           @addPriceListSuccess="addPriceListSuccess"
                           @closeEditPriceListDialog="closeEditPriceListDialog"></EditPriceListDialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="FINANCE" moduleFunction="Preislisten"></NoModuleCard>
    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import FacilityService from '../services/FacilityService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import NoFacilityCard from "../components/cards/NoFacilityCard";
  import EditPriceListDialog from "../components/dialogs/EditPriceListDialog";
  import PriceList from "../entities/PriceList";
  import {Md5} from 'ts-md5/dist/md5';
  import PriceListForm from '../components/forms/PriceListForm';
  import NoModuleCard from '../components/cards/NoModuleCard';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.name).includes(toLower(term)));
    }

    return items;
  };

  function compare( a, b ) {
    if ( a.price < b.price ){
      return -1;
    }
    if ( a.price > b.price ){
      return 1;
    }
    return 0;
  }

  export default {
    name: 'PriceLists',
    components: {
      'vue-simple-spinner': Spinner,
      NoFacilityCard,
      EditPriceListDialog,
      PriceListForm,
      NoModuleCard,
    },

    mounted() {
      this.restoreUserSettings();
      this.reloadPriceLists();

      HttpErrorHandler.maintainDarkMode(this);
    },

    data() {
      return {
        sending: false,
        priceLists: [],
        searched: [],
        search: null,
        selected: null,
        selectedPriceList: null,
        showDeletePriceListDialog: false,
        showEditPriceListDialog: false,
        confirmSaveChangedDataDialog: false,
        success: false,
        dialogMode: 'update',
        newPriceList: null,
        lastSelectedPriceList: null,
        nextSelectedPriceList: null,
        selectedPriceListMd5Hash: null,

        updateAssignedFees: false,

        selectedRowId: 0,
        selectedArrayIndex: -1,

        availableFacilities: [],
        availableFacilitiesMap: {},
        allowedFacilities: [],
        selectedFacilityReferenceNumber: null,
        selectedFacilityName: '',

        postCancelSaveAddPriceList: false,
      }
    },

    methods: {

      sortHourIntervalPricings(priceList) {
        let tmpPriceList = JSON.parse(JSON.stringify(priceList));
        tmpPriceList.hourIntervalPricings.sort(compare);
        return tmpPriceList;
      },

      setMD5Hash() {
        if (this.selectedPriceList) {
          this.selectedPriceListMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.sortHourIntervalPricings(this.selectedPriceList)));
        } else {
          this.selectedPriceListMd5Hash = null;
        }
      },

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        if (this.selectedFacilityReferenceNumber === '*') {
          this.selectedFacilityReferenceNumber = null;
        }
      },

      reloadPriceLists() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {

            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
            this.buildAvailableFacilities();
            this.onSelectFacility();

            clearInterval(reloadIntervalId);
            jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 675px');

          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      buildAvailableFacilities() {
        this.availableFacilities = this.getAvailableFacilities;
        for (let i = 0; i < this.availableFacilities.length; i++) {
          if (this.availableFacilities[i].referenceNumber === '*') {
            this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
          }
        }
        if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
          this.selectedFacilityName = this.availableFacilities[0].name;
        }
        this.allowedFacilities = [];
        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber !== '*') {
            this.allowedFacilities.push(facility);
          }
        }

        this.onSelectFacility();
      },

      onSelectFacility() {
        if (this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

          this.selectedFacilityName = '';
          for (let facility of this.availableFacilities) {
            if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = facility.name;
            }
          }

          if (this.selectedFacilityReferenceNumber === '*') {
            this.getPriceLists();
          } else {
            this.getFacilityPriceLists(this.selectedFacilityReferenceNumber);
          }

          this.searchOnTable();
        }
      },

      getPriceLists() {
        if (this.selectedFacilityReferenceNumber) {
          this.getFacilityPriceLists(this.selectedFacilityReferenceNumber);
        }
      },

      getFacilityPriceLists(referenceNumber) {
        if (!(this.organization && this.organization.facilities && this.organization.facilities.length > 0)) {
          return;
        }
        if (!referenceNumber || referenceNumber === 'null') {
          return;
        }
        if (this.isAdmin || this.isTreasurer) {
          this.sending = true;
          FacilityService.getPriceLists(referenceNumber)
            .then(response => {
              this.priceLists = response.data;
              this.searchOnTable();
              this.sending = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Preislisten für Einrichtung mit Nummer ' + referenceNumber);
              this.sending = false;
            })
        }
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      searchOnTable() {
        this.searched = searchByName(this.priceLists, this.search);
      },

      pristine() {
        if (this.selectedPriceList) {
          return (this.selectedPriceListMd5Hash === Md5.hashAsciiStr(JSON.stringify(this.sortHourIntervalPricings(this.selectedPriceList))));
        } else return false;
      },

      onAddPriceList() {
        this.dialogMode = 'add';

        if (this.selectedPriceListMd5Hash && !this.pristine()) {
          this.postCancelSaveAddPriceList = true;
          this.onSaveChanges();
          return;
        }

        this.addPriceList();
      },

      addPriceList() {
        this.postCancelSaveAddPriceList = false;
        setTimeout(() => jQuery('#' + this.selectedRowId).trigger('click'), 250);

        this.newPriceList = JSON.parse(JSON.stringify(PriceList.priceList));
        this.showEditPriceListDialog = true;
      },

      onDeletePriceList() {
        if (this.selected) {
          this.showDeletePriceListDialog = true;
        }
      },

      deletePriceList() {
        if (this.selectedPriceList) {
          this.sending = true;
          FacilityService.deletePriceList(this.selectedFacilityReferenceNumber, this.selectedPriceList.refNr)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Die Preisliste &nbsp;<b>' + this.selectedPriceList.name + '</b>&nbsp;wurde erfolgreich gelöscht.');

              this.search = null;
              this.selected = null;
              this.selectedPriceList = null;
              this.showDeletePriceListDialog = false;
              this.setMD5Hash();
              this.reloadPriceLists();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Preisliste ' + this.selectedPriceList.name);
              this.sending = false;
            })
        }
      },

      closeEditPriceListDialog() {
        this.showEditPriceListDialog = false;
      },

      addPriceListSuccess(newPriceList) {
        this.$store.commit('successMsg', 'Die Preisliste &nbsp;<b>' + newPriceList.name + '</b>&nbsp;wurde erfolgreich angelegt.');
        this.reloadPriceLists();
      },

      onSelect(item) {
        if (this.selectedPriceListMd5Hash && !this.pristine()) {
          if (item) {
            this.nextSelectedPriceList = JSON.parse(JSON.stringify(item));
          } else {
            this.nextSelectedPriceList = null;
          }
          this.onSaveChanges();
          return;
        }

        if (item && item.refNr) {
          this.dialogMode = 'update';

          this.selectedRowId = item.refNr;
          this.selectedArrayIndex = -1;
          for (let i = 0; i < this.priceLists.length; i++) {
            if (this.priceLists[i].refNr === this.selectedRowId) {
              this.selectedArrayIndex = i;
            }
          }

          let i = 0;
          for (let interval of item.hourIntervalPricings) {
            interval.id = i++;
          }

          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedPriceList = this.selected;
          this.lastSelectedPriceList = this.selected;
          this.setMD5Hash();
        } else {
          this.selected = null;
          this.selectedPriceList = null;
          this.setMD5Hash();
        }
      },

      onSaveChanges() {
        this.updateAssignedFees = false;
        this.confirmSaveChangedDataDialog = true;
      },

      closeSavePriceListDialog() {
        if (this.postCancelSaveAddPriceList) {
          this.addPriceList();
        }

        this.confirmSaveChangedDataDialog = false;
        this.setMD5Hash();
        this.onSelect(this.nextSelectedPriceList);
      },

      validatePriceList() {
        if (this.dialogMode === 'update') {
          this.$refs.priceListForm.validatePriceList();

          if (!this.$refs.priceListForm.invalid) {
            this.onSaveChanges();
          } else {
            // this.confirmMissingData();
          }
        }
      },

      updatePriceList() {
        this.sending = true;
        FacilityService.updatePriceList(this.selectedFacilityReferenceNumber, this.selectedPriceList, this.updateAssignedFees)
          .then(response => {
            this.priceLists = response.data.priceLists;
            this.searchOnTable();
            this.sending = false;

            this.$store.commit('successMsg', 'Die Änderungen an der Preisliste &nbsp;<b>' + this.lastSelectedPriceList.name + '</b>&nbsp;wurden erfolgreich durchgeführt.');

            this.confirmSaveChangedDataDialog = false;
            this.selectedPriceListMd5Hash = null;

            if (this.selectedArrayIndex < this.priceLists.length) {
              this.lastSelectedPriceList = this.priceLists[this.selectedArrayIndex];
              this.selectedRowId = this.priceLists[this.selectedArrayIndex].refNr;
            }

            this.onSelect(this.nextSelectedPriceList);

            setTimeout(() => jQuery('#' + this.selectedRowId).trigger('click'), 250);

            if (this.postCancelSaveAddPriceList) {
              this.addPriceList();
            }

          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Preisliste "' + this.lastSelectedPriceList.name + '"');
            this.sending = false;
          })
      },
    },

    computed: {
      dataAvailable() {
        return this.labels && this.organization && this.organization.facilities && this.user &&
            this.user.allowedFacilities && this.user.allowedFacilities.length > 0 &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacilityAdmin() {
        return this.$store.getters.facility;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      organization() {
        return this.$store.getters.organization;
      },

      user() {
        return this.$store.getters.user;
      },

      labels() {
        return this.$store.getters.labels
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 800px;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .title {
    color: chocolate;
    font-weight: bold;
    font-size: larger;
  }

  .title-thin {
    color: cornflowerblue;
    font-weight: lighter;
    font-size: larger;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }

  .md-tooltip-block {
    font-size: medium;
    height: auto;
    max-width: 375px;
    white-space: pre-wrap;
  }
</style>
