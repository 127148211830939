<template>
  <div v-if="priceList">
    <md-dialog :md-click-outside-to-close="false" id="createNewPriceListDialog" :md-active.sync="showDialog" @md-closed="closeDialog">

      <md-dialog-title>

        <form novalidate @submit.prevent="validatePriceList">

          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'update'" class="cell large-5 medium-5">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Preisliste bearbeiten
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-5">
              <i class="fi-price-tag"></i>&nbsp;&nbsp;&nbsp;Neue Preisliste hinzufügen
            </div>
            <div class="cell large-2 medium-2" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <i class="fi-price-tag"></i>&nbsp;&nbsp;Hinzufügen
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>

        </form>

      </md-dialog-title>

      <md-dialog-content id="dialogContentId">
        <PriceListForm v-if="priceList" ref="priceListForm" :priceList="priceList" :sending="sending" :mode="mode">
        </PriceListForm>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingPriceListDataDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <i class="fi-pricetag"></i>&nbsp;&nbsp;&nbsp;Unvollständigen
          Datensatz für Preisliste bestätigen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll der unvollständige Datensatz wirklich gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="confirmProcessingData" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja,
            speichern
          </div>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="closeConfirmationDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeConfirmationDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>

  import jQuery from 'jquery';
  import HttpErrorHandler from '../../services/HttpErrorHandler'
  import FacilityService from "../../services/FacilityService";
  import PriceListForm from '../forms/PriceListForm';
  import Spinner from 'vue-simple-spinner'
  import SessionTime from '../animations/SessionTime';

  export default {

    name: 'EditPriceListDialog',

    components: {
      PriceListForm,
      'vue-simple-spinner': Spinner,
      SessionTime,
    },

    props: {
      priceList: {
        required: true
      },
      facilityReferenceNumber: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    data: () => ({
      success: true,
      sending: false,
      confirmMissingPriceListDataDialog: false,
    }),

    methods: {

      addPriceList() {
        this.sending = true;
        this.success = false;
        FacilityService.addPriceList(this.facilityReferenceNumber, this.priceList)
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$emit("closeEditPriceListDialog");
            this.$emit("addPriceListSuccess", this.priceList, response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen der Preisliste ' + this.priceList.name + ' für Einrichtung mit Ref-Nummer ' + this.facilityReferenceNumber);
            this.sending = false;
          })
      },

      confirmMissingData() {
        jQuery('#createNewPriceListDialog').attr("style", "display:none");
        this.confirmMissingPriceListDataDialog = true;
      },

      closeConfirmationDialog() {
        this.confirmMissingPriceListDataDialog = false;
        jQuery('#createNewPriceListDialog').attr("style", "display:normal");
      },

      confirmProcessingData() {
        this.closeConfirmationDialog();
        if (this.mode === 'add') {
          this.addPriceList();
        }
      },

      validatePriceList() {
        this.$refs.priceListForm.validatePriceList();

        if (!this.$refs.priceListForm.invalid) {
          if (this.mode === 'add') {
            this.addPriceList();
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditPriceListDialog");
      }
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style>
  .md-dialog /deep/.md-dialog-container {
    max-width: 95%;
    max-height: 95%;
    min-height: 95%;
  }
</style>
